<template>
  <b-modal
    id="myCustomModal"
    :title="crudmodaltitle"
    size="xlg"
    hide-footer
    scrollable
    centered
    button-size="lg"
  >
    <form
      id="formulaire"
      class="inherit"
      v-on:submit.prevent="onSubmit"
      :keydown="
        (e) => {
          e.preventDefault();
        }
      "
    >
      <div class="content-wrapper inherit">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="col-lg-12"></div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="date_facture">Date facture</label>
                  <input
                    type="text"
                    v-model="crudform.date_facture_formatted"
                    class="form-control form-control-sm text-center"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="num_facture">{{
                    $t("traitement.document_vente.modal.numero_facture")
                  }}</label>
                  <input
                    type="text"
                    v-model="crudform.num_facture"
                    class="form-control form-control-sm text-center"
                    name="num_facture"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-12" v-if="showMagasinInfo">
                <div class="form-group">
                  <label for="">Magasin</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    :value="crudform.nom_magasin"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-12" v-if="showMagasinInfo">
                <div class="card card-body bg-light">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>{{
                          $t("traitement.document_vente.modal.nom_client")
                        }}</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.nom_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Adresse</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.adresse_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>E-mail</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.mail_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Tel</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.tel_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>NIF</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.nif_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Stat</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.stat_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>RCS</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.rcs_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Quittance</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.quittance"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-12"
                v-if="
                  crudform.num_bon_livraison != null &&
                  crudform.num_bon_livraison != ''
                "
              >
                <div class="form-group">
                  <label for="num_bon_livraison"
                    >Numero des Bons de livraison</label
                  >
                  <input
                    type="text"
                    v-model="crudform.num_bon_livraison"
                    class="form-control form-control-sm"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="">{{
                    $t("traitement.document_vente.modal.comentaire")
                  }}</label>
                  <textarea
                    rows="3"
                    class="form-control form-control-sm"
                    name="commentaire"
                    v-model="crudform.commentaire"
                    readonly
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="">
              <label>{{
                $t("traitement.document_vente.modal.detail_document_vente")
              }}</label>

              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      {{ $t("traitement.document_vente.modal.designation") }}
                    </th>
                    <th>{{ $t("traitement.document_vente.modal.qte") }}</th>
                    <th class="d-none">PU R</th>
                    <th>{{ $t("traitement.document_vente.modal.pu_ht") }}</th>
                    <th class="d-none">
                      {{ $t("traitement.document_vente.modal.tva") }}
                    </th>
                    <!-- <th>P.U TTC</th> -->
                    <!-- <th>Montant TVA</th> -->
                    <th class="d-none">
                      {{ $t("traitement.document_vente.modal.montant_ht") }}
                    </th>
                    <th>
                      {{ $t("traitement.document_vente.modal.montant_ttc") }}
                    </th>
                    <th>
                      {{ $t("traitement.document_vente.modal.remise") }}
                    </th>
                    <th>
                      {{
                        $t(
                          "traitement.document_vente.modal.montant_avec_remise"
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(i, index) in crudform.details" :key="index">
                    <td>
                      <input
                        type="text"
                        name="designation[]"
                        class="form-control form-control-sm"
                        v-model="crudform.details[index].designation"
                        :title="crudform.details[index].designation"
                        readonly
                      />
                      <input
                        type="text"
                        name="articles_id[]"
                        class="d-none"
                        v-model="crudform.details[index].articles_id"
                      />
                    </td>
                    <td>
                      <vue-numeric
                        decimal-separator="."
                        v-if="crudform.from_bl == 0"
                        currency=""
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.details[index].quantite"
                        class="text-right form-control form-control-sm"
                        @input="updateMontant()"
                        :readonly="true"
                      ></vue-numeric>
                      <input
                        v-if="crudform.from_bl == 1"
                        type="text"
                        class="text-right form-control form-control-sm"
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.details[index].quantite
                          )
                        "
                        readonly
                      />
                      <input
                        type="text"
                        name="quantite[]"
                        class="d-none"
                        v-model="crudform.details[index].quantite"
                      />
                    </td>
                    <td class="d-none">
                      <input
                        type="text"
                        name="prix_revient[]"
                        v-model="crudform.details[index].prix_revient"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.details[index].pau_ht
                          ) + ' Ar'
                        "
                        class="text-right form-control form-control-sm d-none"
                        readonly
                        @input="updateMontant()"
                      />
                      <input
                        type="text"
                        name="pau_ht[]"
                        class="d-none"
                        v-model="crudform.details[index].pau_ht"
                      />
                      <vue-numeric
                        :readonly="true"
                        decimal-separator="."
                        @input="updateMontant()"
                        class="text-right form-control form-control-sm"
                        currency="Ar"
                        separator="space"
                        v-bind:precision="2"
                        currency-symbol-position="suffix"
                        empty-value="0"
                        v-model="crudform.details[index].pau_ht"
                      ></vue-numeric>
                    </td>
                    <td width="80px" class="d-none">
                      <select
                        class="form-control form-control-sm"
                        @change="updateMontant()"
                        v-model="crudform.details[index].tva"
                        :disabled="crudform.from_bl == 1"
                      >
                        <option value="0">0%</option>
                        <option value="20">20%</option>
                      </select>
                      <input
                        type="text"
                        name="tva[]"
                        class="d-none"
                        v-model="crudform.details[index].tva"
                      />
                    </td>
                    <td class="d-none">
                      <input
                        readonly
                        type="text"
                        class="text-right form-control form-control-sm"
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.details[index].montant_ht
                          ) + ' Ar'
                        "
                      />
                      <input
                        type="text"
                        name="montant_ht[]"
                        class="d-none"
                        v-model="crudform.details[index].montant_ht"
                      />
                    </td>
                    <td>
                      <input
                        readonly
                        type="text"
                        class="form-control form-control-sm text-right"
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.details[index].montant_ttc
                          ) + ' Ar'
                        "
                      />
                      <input
                        type="text"
                        name="montant_ttc[]"
                        class="d-none"
                        v-model="crudform.details[index].montant_ttc"
                      />
                    </td>
                    <td>
                      <vue-numeric
                        decimal-separator="."
                        v-if="crudform.from_bl == 0"
                        currency="Ar"
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.details[index].remise"
                        class="text-right form-control form-control-sm"
                        @input="updateMontant()"
                        :readonly="true"
                      ></vue-numeric>
                      <input
                        v-if="crudform.from_bl == 1"
                        type="text"
                        class="text-right form-control form-control-sm"
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.details[index].remise
                          )
                        "
                        readonly
                      />
                      <input
                        type="text"
                        name="remise[]"
                        class="d-none"
                        v-model="crudform.details[index].remise"
                      />
                    </td>
                    <td>
                      <input
                        readonly
                        type="text"
                        class="form-control form-control-sm text-right"
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.details[index].montant_a_payer
                          ) + ' Ar'
                        "
                      />
                      <input
                        type="text"
                        name="montant_a_payer[]"
                        class="d-none"
                        v-model="crudform.details[index].montant_a_payer"
                      />
                    </td>
                  </tr>
                  <tr v-if="crudform.details.length == 0">
                    <td class="text-center" colspan="6">
                      Aucune article n'a encore été choisit
                    </td>
                  </tr>
                </tbody>
                <tfoot v-if="crudform.details.length > 0">
                  <tr>
                    <td class="" colspan="3"></td>
                    <td class="text-right d-none">
                      <input
                        type="hidden"
                        v-model="crudform.total_tva"
                        name="total_tva"
                      />
                      <vue-numeric
                        decimal-separator="."
                        class="form-control form-control-sm text-right d-none"
                        currency="Ar"
                        separator="space"
                        v-bind:precision="2"
                        readonly
                        currency-symbol-position="suffix"
                        empty-value="0"
                        v-model="crudform.total_tva"
                      ></vue-numeric>
                      Total
                    </td>
                    <td class="d-none">
                      <input
                        type="hidden"
                        v-model="crudform.total_ht"
                        name="total_ht"
                      />
                      <vue-numeric
                        decimal-separator="."
                        class="form-control form-control-sm text-right"
                        currency="Ar"
                        separator="space"
                        v-bind:precision="2"
                        readonly
                        currency-symbol-position="suffix"
                        empty-value="0"
                        v-model="crudform.total_ht"
                      ></vue-numeric>
                    </td>
                    <td>
                      <input
                        type="hidden"
                        v-model="crudform.total_ttc"
                        name="total_ttc"
                      />
                      <vue-numeric
                        decimal-separator="."
                        class="form-control form-control-sm text-right"
                        currency="Ar"
                        separator="space"
                        v-bind:precision="2"
                        readonly
                        currency-symbol-position="suffix"
                        empty-value="0"
                        v-model="crudform.total_ttc"
                      ></vue-numeric>
                    </td>
                    <td>
                      <input
                        type="hidden"
                        v-model="crudform.total_remise_detail"
                        name="total_remise_detail"
                      />
                      <vue-numeric
                        decimal-separator="."
                        class="form-control form-control-sm text-right"
                        currency="Ar"
                        separator="space"
                        v-bind:precision="2"
                        readonly
                        currency-symbol-position="suffix"
                        empty-value="0"
                        v-model="crudform.total_remise_detail"
                      ></vue-numeric>
                    </td>
                    <td>
                      <input
                        type="hidden"
                        v-model="crudform.total_montant_avec_remise_detail"
                        name="total_montant_avec_remise_detail"
                      />
                      <vue-numeric
                        decimal-separator="."
                        class="form-control form-control-sm text-right"
                        currency="Ar"
                        separator="space"
                        v-bind:precision="2"
                        readonly
                        currency-symbol-position="suffix"
                        empty-value="0"
                        v-model="crudform.total_montant_avec_remise_detail"
                      ></vue-numeric>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="5" class="text-right">Montant total</td>
                    <td colspan="">
                      <vue-numeric
                        decimal-separator="."
                        class="form-control form-control-sm text-right"
                        currency="Ar"
                        separator="space"
                        v-bind:precision="2"
                        readonly
                        currency-symbol-position="suffix"
                        empty-value="0"
                        v-model="crudform.total_montant_avec_remise_detail"
                      ></vue-numeric>
                    </td>
                  </tr>

                  <tr
                    v-show="
                      crudform.type_facture == 'FACTURE' ||
                      crudform.type_facture == 'NOTE D\'ACHAT' ||
                      crudform.type_facture == 'AVOIR CLIENT'
                    "
                  >
                    <td colspan="5" class="text-right">Remise</td>
                    <td colspan="">
                      <vue-numeric
                        decimal-separator="."
                        v-if="crudform.from_bl == 0"
                        currency="Ar"
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.remise"
                        class="text-right form-control form-control-sm"
                        @input="updateMontant()"
                        :readonly="true"
                      ></vue-numeric>

                      <input
                        v-if="crudform.from_bl == 1"
                        type="text"
                        class="text-right form-control form-control-sm"
                        :value="new Intl.NumberFormat().format(crudform.remise)"
                        readonly
                      />
                      <input
                        v-model="crudform.remise"
                        type="text"
                        name="remise_parent"
                        class="d-none"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" class="text-right">Net à payer</td>
                    <td colspan="">
                      <input
                        :value="crudform.total_a_payer"
                        type="text"
                        name="total_a_payer"
                        class="form-control form-control-sm text-right d-none"
                        readonly
                      />
                      <vue-numeric
                        decimal-separator="."
                        currency="Ar"
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.total_a_payer"
                        class="text-right form-control form-control-sm"
                        readonly
                      ></vue-numeric>
                    </td>
                  </tr>
                  <tr
                    v-show="
                      crudform.type_facture == 'FACTURE' ||
                      crudform.type_facture == 'AVOIR CLIENT'
                    "
                  >
                    <td colspan="5" class="text-right">Acompte</td>
                    <td colspan="">
                      <vue-numeric
                        decimal-separator="."
                        v-if="crudform.type_facture != 'NOTE D\'ACHAT'"
                        currency="Ar"
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.acompte"
                        class="text-right form-control form-control-sm"
                        :readonly="true"
                      ></vue-numeric>
                      <input
                        v-model="crudform.acompte"
                        type="text"
                        name="acompte"
                        class="d-none"
                      />
                    </td>
                  </tr>
                </tfoot>
              </table>
              <table class="table table-bordered mt-2">
                <thead>
                  <tr>
                    <td colspan="8" class="text-center">
                      {{ $t("historique_reglement.historique_paiement") }}
                      <span>
                        <span class="text-danger"
                          >N°{{ crudform.num_facture }}</span
                        ></span
                      >
                    </td>
                  </tr>
                  <tr>
                    <th class="text-center">
                      {{ $t("historique_reglement.date") }}
                    </th>
                    <th>{{ $t("historique_reglement.montant") }}</th>
                    <th>{{ $t("historique_reglement.reference") }}</th>
                    <th class="text-center">
                      {{ $t("historique_reglement.mode_paiement") }}
                    </th>
                    <!-- <th>Piece Jointe</th> -->
                    <th>{{ $t("historique_reglement.remarque") }}</th>
                    <th class="text-center">
                      {{ $t("historique_reglement.date_enregistrement") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="r in listdata.reglements" :key="r.id">
                    <td class="text-center vuetable-slot">
                      {{ r.date_reglement_formatted }}
                    </td>
                    <td class="text-right text-bold vuetable-slot">
                      <vue-numeric
                        decimal-separator="."
                        currency="Ar"
                        separator="space"
                        v-bind:precision="2"
                        read-only
                        currency-symbol-position="suffix"
                        empty-value="0"
                        v-model="r.montant"
                      ></vue-numeric>
                    </td>
                    <td class="vuetable-slot">{{ r.reference }}</td>
                    <td class="vuetable-slot text-center">
                      {{ r.mode_paiement }}
                    </td>
                    <!-- <td>{{ r.piece_jointe }}</td> -->
                    <td class=""><span v-html="r.remarques"></span></td>
                    <td class="vuetable-slot text-center">
                      {{ r.date_heure_enregistrement }}
                    </td>
                  </tr>

                  <tr v-if="listdata.reglements.length == 0">
                    <td colspan="8" class="text-center">
                      Aucun paiement n'a encore été effectué
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <input
        type="hidden"
        v-model="crudform.id"
        class="form-control"
        id="id"
        placeholder="id"
        name="id"
      />
      <input type="hidden" v-model="crudform.from_bl" name="from_bl" />
      <input
        v-for="id in crudform.bon_livraison_id"
        :key="id"
        type="hidden"
        :value="id"
        name="bon_livraison_id[]"
      />

      <input
        type="hidden"
        name="mode_paiement"
        v-model="crudform.mode_paiement"
      />
      <input type="hidden" name="remarques" v-model="crudform.remarques" />
      <input type="hidden" name="reference" v-model="crudform.reference" />
      <input
        type="hidden"
        name="date_echeance"
        v-model="crudform.date_echeance"
      />
      <input type="hidden" v-model="has_echeance" name="has_echeance" />
      <div class="myCustomModal modal-footer">
        <b-button variant="secondary" @click="closeModal()">{{
          $t("general.fermer")
        }}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "myCustomModal",
  data() {
    return {
      datepicker: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      crudform: {
        id: "",
        num_facture: "",
        date_facture_formatted: moment().format("DD/MM/YYYY"),
        date_echeance_facture_formatted: moment().format("DD/MM/YYYY"),
        clients_id: "",
        magasin_id: "",
        commentaire: "",
        total_tva: "",
        total_ht: "",
        total_ttc: "",
        total_remise: "",
        total_a_payer: "",
        acompte: "",
        from_bl: 0,
        type_facture: "FACTURE",
        type_client: "INCONNU",
        details: [],
        degats: [],
        bon_livraison_id: [],
        montant_recu: "",
        montant_rendu: "",
        reference: "",
        mode_paiement: "ESPECE",
        date_echeance: moment().format("DD/MM/YYYY"),
        remarques: "",
        total_remise_detail: "",
        total_montant_avec_remise_detail: "",
        nom_client_inconnu: "",
        not_editable: false,
      },
      crudmodaltitle: this.$t(
        "traitement.document_vente.modal.nouveau_facture"
      ),
      point_de_ventes: [],
      point_de_ventes_id: "",
      isReadonly: false,
      isShowButton: true,
      users_id: "",
      avec_degats: false,
      vente_en_gros: false,
      has_echeance_facture: false,
      has_echeance: false,
      listdata: {
        reglements: [],
      },
    };
  },
  props: {
    showMagasinInfo: {
      type: Boolean,
      default: true,
      required: false,
    },
    type: {
      type: String,
      default: "App",
      required: false,
    },
  },
  methods: {
    openModal() {
      this.$bvModal.show("myCustomModal");
    },
    closeModal() {
      this.$bvModal.hide("myCustomModal");
    },
    delRow: function (i) {
      this.crudform.details.splice(i, 1);
      this.updateMontant();
    },
    updateMontant: function () {
      this.crudform.total_a_payer = 0;
      this.crudform.total_ht = 0;
      this.crudform.total_ttc = 0;
      this.crudform.total_tva = 0;
      this.crudform.total_remise = 0;

      this.crudform.total_ht = 0;
      this.crudform.total_ttc = 0;
      this.crudform.total_remise_detail = 0;
      this.crudform.total_montant_avec_remise_detail = 0;
      this.crudform.total_tva = 0;

      //console.log("CHANGE: ");

      var montant_a_payer = 0;
      for (var i = 0; i < this.crudform.details.length; i++) {
        if (
          this.crudform.details[i].pau_ht == null ||
          this.crudform.details[i].pau_ht == ""
        ) {
          this.crudform.details[i].pau_ht = 0;
        }
        if (
          this.crudform.details[i].quantite == null ||
          this.crudform.details[i].quantite == ""
        ) {
          this.crudform.details[i].quantite = 0;
        }
        this.crudform.details[i].pau_ttc =
          parseFloat(this.crudform.details[i].pau_ht) *
          (parseFloat(this.crudform.details[i].tva) / 100 + 1);
        this.crudform.details[i].montant_ht =
          parseFloat(this.crudform.details[i].pau_ht) *
          parseFloat(this.crudform.details[i].quantite);
        this.crudform.details[i].montant_ttc =
          parseFloat(this.crudform.details[i].pau_ttc) *
          parseFloat(this.crudform.details[i].quantite);
        this.crudform.details[i].montant_tva =
          parseFloat(this.crudform.details[i].montant_ttc) -
          parseFloat(this.crudform.details[i].montant_ht);
        if (
          this.crudform.details[i].remise == "" ||
          this.crudform.details[i].remise == null
        ) {
          this.crudform.details[i].remise = 0;
        }
        this.crudform.details[i].montant_a_payer =
          this.crudform.details[i].montant_ttc -
          this.crudform.details[i].remise;

        // Mettre a jour les totaux
        // this.crudform.total_ht += this.crudform.details[i].montant_ht;
        // this.crudform.total_ttc += this.crudform.details[i].montant_ttc;
        // this.crudform.total_tva += this.crudform.details[i].montant_tva;
        // this.crudform.total_remise += this.crudform.details[i].remise;
        montant_a_payer += this.crudform.details[i].montant_a_payer;

        this.crudform.total_ht += this.crudform.details[i].montant_ht;
        this.crudform.total_tva += this.crudform.details[i].montant_tva;
        this.crudform.total_ttc += this.crudform.details[i].montant_ttc;
        this.crudform.total_remise_detail += this.crudform.details[i].remise;
        this.crudform.total_montant_avec_remise_detail +=
          this.crudform.details[i].montant_a_payer;
      }
      // var remise = 0;
      // if (this.crudform.remise != '' && this.crudform.remise != null) {
      //     remise = this.crudform.remise;
      // }
      //console.log(this.crudform.total_remise);
      //console.log(remise);

      this.crudform.total_a_payer =
        montant_a_payer - parseFloat(this.crudform.remise);
      if (
        this.crudform.id == "" ||
        this.crudform.id == null ||
        this.crudform.id == undefined
      ) {
        this.crudform.acompte = this.crudform.total_a_payer;
      }

      // this.crudform.total_a_payer = montant_a_payer - parseFloat(remise);
      // if (this.crudform.id == '' || this.crudform.id == null || this.crudform.id == undefined) {
      //     this.crudform.acompte = this.crudform.total_a_payer;
      // }
    },
    getClient: function (id_client) {
      if (id_client == "" || id_client == null) {
        this.client = {};
      } else {
        axios
          .get(this.BASE_URL + "/clients/get/" + id_client)
          .then((response) => {
            this.client = response.data[0];
          });
      }
    },
    fetchreglements: function (id_facture) {
      this.listdata.reglements = [];
      var lien = "";
      if (this.type == "App") {
        lien = this.BASE_URL + "/facturesreglement/fetchbyfacture/";
      } else {
        lien = this.BASE_URL + "/clientreglements/fetchbyfacture/";
      }
      axios.get(lien + id_facture).then((response) => {
        this.listdata.reglements = response.data;
      });
    },
    editRow(rowData) {
      this.crudmodaltitle = "Information sur : ";
      //alert("You clicked edit on" + JSON.stringify(rowData))
      this.crudform.details = [];
      this.crudform.degats = [];
      axios
        .get(this.BASE_URL + "/factures/get/" + rowData.id)
        .then((response) => {
          console.log(response.data[0]);
          response.data[0].montant_recu = "";
          response.data[0].montant_rendu = "";
          response.data[0].reference = "";
          response.data[0].mode_paiement = "ESPECE";
          response.data[0].remarques = "";
          response.data[0].type_client =
            response.data[0].clients_id > 1 ? "CLIENT" : "INCONNU";
          response.data[0].not_editable = false;
          response.data[0].type_facture =
            response.data[0].type_facture == "FACTURE" &&
            response.data[0].statut_mvt == 0
              ? "AVOIR CLIENT"
              : response.data[0].type_facture;
          this.has_echeance_facture =
            response.data[0].date_echeance_facture == "" ||
            response.data[0].date_echeance_facture == null
              ? false
              : true;

          if (response.data[0].degats.length > 0) {
            this.avec_degats = true;
          } else {
            this.avec_degats = false;
          }
          // var date_enr = moment(response.data[0].date_heure_enregistrement).format('DD/MM/YYYY');
          // if (date_enr < moment().format('DD/MM/YYYY')) {
          //     response.data[0].not_editable = true;
          // }
          this.crudform = response.data[0];
          this.crudmodaltitle +=
            response.data[0].type_facture +
            " N°" +
            response.data[0].num_facture;
          this.updateMontant();
        });
      this.fetchreglements(rowData.id);
      this.openModal();
    },
  },

  mounted() {
    console.log(this.showMagasinInfo);
  },
};
</script>
